import React, { useState } from "react"

import { Layout } from "../../ReusableComponents/Wrapper/Layout"
import { toast } from 'react-toastify';

import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from 'react-loading-skeleton'

export const FaqBot = () => {

    const [textValue, setTextValue] = useState("")
    const [faqResponse, setFaqResponse] = useState("Answer to your question would show up here")
    const [isLoading, setLoading] = useState(false)

    const onTextChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextValue(e.target.value)
    }

    const endpoint = () => {
        if (window.location.hostname === "local.myyogateacher.com") {
            return `http://localhost:8000/query_faq_ai`
        } else {
            return `${window.location.origin}/query_faq_ai`
        }
    }

    const fetchFaq = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()

        if (textValue.trim().length < 10) {
            toast.warn("Please Enter text of atleast 10 characters", {
                position: 'top-right'
            })
            return
        }

        setLoading(true)

        const response = await fetch(endpoint(), {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                query: textValue
            })
        })

        const result = await response.json()
        const textResult: string = result.data || ''
        if (textResult.trim().toLowerCase() === "unknown") {
            setFaqResponse("What exactly are you looking for?")
        } else {
            setFaqResponse(result.data)
        }

        setLoading(false)
    }

    return (
        <Layout title="Faq Bot">
            <div className="w-full flex flex-row mt-24 px-16 flex-wrap justify-around">
                <div className="flex flex-col w-104 text-center ml-16">
                    <span className="text-lg">Submit Question</span>
                    <div className="mt-5 w-full">
                        <textarea className="h-48 rounded-lg p-4 w-full border-2 border-blue-400 resize-none"
                            placeholder="Enter question for bot"
                            maxLength={100}
                            onChange={onTextChanged} value={textValue} />
                    </div>

                    <button className="rounded-lg bg-green-400 hover:bg-green-600 w-full h-auto p-2 mt-5"
                        onClick={fetchFaq}>Submit</button>
                </div>

                <div className="flex flex-col w-104 text-center ml-16">
                    <span className="text-lg">Faq Bot Reply</span>
                    {isLoading && (
                        <div className="w-full h-48 mt-5 p-4">
                            <Skeleton count={5} />
                        </div>
                    )}
                    {!isLoading && (
                        <span className="bg-gray-100 w-full h-48 mt-5 text-start p-4 overflow-y-scroll border-2 border-green-400 rounded-lg whitespace-pre-line">
                            {faqResponse}
                        </span>
                    )}
                </div>
            </div>
        </Layout>
    )
}