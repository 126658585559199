import React from "react"

import { Layout } from "../../ReusableComponents/Wrapper/Layout"
import { Link } from "react-router-dom"

export const ChooseApp = () => {

    return (
        <Layout title="Choose App">
            <div className="w-full flex flex-row mt-24 px-16 flex-wrap justify-between">
                <div className="flex flex-col w-72 text-center">
                    <ul className="list-disc">
                        <li className="pl-2">
                            <Link to={"/pose"} className="hover:text-blue-600 text-blue-500 float-start">Open Flexibility Test</Link>
                        </li>
                        <li className="pl-2">
                            <Link to={"/faqbot"} className="hover:text-blue-600 text-blue-500 float-start">Open Faq Bot</Link>
                        </li>
                        <li className="pl-2">
                            <Link to={"/record-detect"} className="hover:text-blue-600 text-blue-500 float-start">Record Detected</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </Layout>
    )
}